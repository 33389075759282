import React from 'react';
import Layout from '../components/layout';

function Download() {
    return ( 
        <Layout>
            <h1>Download</h1>
        </Layout>
     );
}

export default Download;